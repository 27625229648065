<template>
  <!-- 公共数据源-管理服务项目 -->
  <div class="container">
    <a-page-header
      :title="title"
      sub-title="源数据管理，操作即时生效，谨慎操作！"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">{{title}}（源数据）</span>
          </div>
        </div>
        <!-- <div class="item">
          <a-button
            type="primary"
            @click="
              $jump({
                path: '/publicServiceAdd',
                query: {
                  categoryId: categoryId
                }
              })
            "
          >
            新增服务项目
          </a-button>
        </div> -->
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1220 }"
        @expand="handleExpand"
      >
       
        <!-- <template slot="action" slot-scope="record">
          <a-button type="link" @click="handleOpen(record)">
            新增{{title}}服务
          </a-button>
          <a-button
            type="link"
            @click="
              $jump({
                path: '/publicServiceAdd',
                query: {
                  categoryId: categoryId,
                  servingId: record.id
                }
              })
            "
          >
            编辑
          </a-button>
        </template> -->
        <a-table
          class="inner-table"
          slot="expandedRowRender"
          slot-scope="innerRecord"
          :columns="innerColumns"
          :data-source="innerRecord.rate"
          :pagination="false"
          :row-key="(record) => record.id"
          :loading="expandedLoading"
          :scroll="{ x: 1320 }"
        >
          <template slot="rate" slot-scope="records">
           {{records.rate*100}}
          </template>
          <template slot="price" slot-scope="records">
            <a-statistic prefix="￥" :value="records.price" />
          </template>
          <!-- <template slot="innerAction" slot-scope="record">
            <a-button type="link" @click="handleEdit(record)"> 编辑 </a-button>
            <a-button type="link" @click="handleDelete(record.id)">
              删除
            </a-button>
          </template> -->
        </a-table>
      </my-table>
    </div>

    <drawer :title="drawerTitle" :width="376" :visible="visible">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item
          class="ant-form-item-input"
          ref="name"
          label="设备名称"
          prop="name"
        >
          <a-input
            v-model="form.name"
            placeholder="请输入名称"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="price" label="服务费用" prop="price">
          <a-input
            v-model="form.price"
            placeholder="请输入服务费用"
            @blur="
              () => {
                $refs.price.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="subtitle" label="设备属性" prop="subtitle">
          <a-input
            v-model="form.subtitle"
            placeholder="请输入设备属性"
            @blur="
              () => {
                $refs.subtitle.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="specifiId" label="设备规格" prop="specifiId">
          <a-select v-model="form.specifiId" placeholder="请选择规格">
            <a-select-option
              v-for="item in specifis"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="sort" label="排序" prop="sort">
          <a-input
            v-model="form.sort"
            placeholder="请输入排序"
            @blur="
              () => {
                $refs.sort.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          prop="cover"
          label="展示图"
          extra="建议使用PNG格式图片；尺寸为200px*200px"
        >
          <a-upload
            nname="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            :data="{
              token: qiniuData.token
            }"
            @change="handleChange"
          >
            <div class="avatar-box" v-if="form.cover">
              <img class="img" :src="form.cover" alt="avatar" />
            </div>
            <div v-else>
              <a-icon :type="imgLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
      <div class="drawer-btn-wrap">
        <a-button @click="handleDrawerClose"> 取消 </a-button>
        <a-button type="primary" @click="handleDrawerSubmit"> 提交 </a-button>
      </div>
    </drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import drawer from '../../components/drawer/index'
let innerDataIndex = -1
let deviceId = ''
export default {
  data() {
    return {
      categoryId: '',
      title: '渠道经理业务比例配置',
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '一级角色名称',
          dataIndex: 'name',
          width: 200
        },
        // {
        //   title: '操作',
        //   width: 250,
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      innerColumns: [
        {
          title: '排序',
          dataIndex: 'sort',
          width: 60,
           customRender: (text, record, index) => index + 1
        },
        {
          title: '角色名称',
          dataIndex: 'name',
          width: 200
        },
        //  {
        //   title: ' 自增id',
        //   dataIndex: 'id',
        //   width: 200
        // },
          {
          title: '业绩基数',
          dataIndex:'baseint',
          width: 200
        },
         {
          title: '业绩比例',
          scopedSlots: { customRender: 'rate' }
        },
        // {
        //   title: '分成基数设置',
        //   dataIndex: 'subtitle',
        //   width: 300
        // },
        // {
        //   title: '二级分成比例',
        //   dataIndex: 'rate',
        //   width: 100
        // },
        // {
        //   title: '缩略图',
        //   width: 100,
        //   scopedSlots: { customRender: 'cover' }
        // },
        // {
        //   title: '默认售价',
        //   align: 'right',
        //   width: 100,
        //   scopedSlots: { customRender: 'price' }
        // },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createAt'
        // },
        // {
        //   title: '操作',
        //   fixed: 'right',
        //   width: 160,
        //   scopedSlots: { customRender: 'innerAction' }
        // }
      ],
      loading: true,
      expandedLoading: true,
      imgLoading: false,
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      drawerTitle: '',
      visible: false,
      form: {
        name: '',
        price: '',
        subtitle: '',
        cover: '',
        sort: '',
        specifiId: undefined
      },
      rules: {
        // 绑定地区
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        price: [{ required: true, message: '请输入服务费用', trigger: 'blur' }],
        subtitle: [
          { required: true, message: '请输入设备属性', trigger: 'blur' }
        ],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传缩略图', trigger: 'change' }],
        specifiId: [
          { required: true, message: '请选择规格', trigger: 'change' }
        ]
      },
      specifis: [],
      dataList: ''
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  components: {
    drawer
  },
  created() {
    const query = this.$route.query
    this.categoryId = query.id
    this.chateCommunityBcRoseRate()
  },
  methods: {
    chateCommunityBcRoseRate() {
      // 获取公共数据服务项目
      const data = {
        id: this.categoryId
      }
      this.$axios.chateCommunityBcRoseRate(data).then((res) => {
        this.dataSource = res.data.data
        console.log("dataSource",this.dataSource)
        this.loading = false
      })
    },
    getPublicServiceItemsEquipment() {
      // 获取公共数据服务项目设备
      const dataSource = this.dataSource[innerDataIndex]
      this.innerRecord = this.dataSource[innerDataIndex]
      console.log('innerDataIndex', this.innerRecord,dataSource)
    //   let data = {}
    //   if (innerDataIndex!==-1) {
    //     data = {
    //       servingId: dataSource.id
    //     }
    //     console.log("data",data)
    //   } else {
    //     data = {
    //       servingId: this.dataList.id
    //     }
    //   }
    //   this.$axios.getPublicServiceItemsEquipment(data).then((res) => {
        this.expandedLoading = false
    //     dataSource.data = res.data.data
    //     console.log('res.data.data', res.data.data)
        // this.dataSource.splice(innerDataIndex, 1, dataSource)
    //   })
    },
    handleExpand(e, r) {
      // 监听表格展开
      this.expandedLoading = e
      if (e) {
        innerDataIndex = this.dataSource.findIndex((f) => f.id === r.id)
        this.getPublicServiceItemsEquipment()
      }
    },
    handleChange(info) {
      // 图片上传
      if (info.file.status === 'uploading') {
        if (this.form.cover) {
          this.form.cover = ''
        }
        this.imgLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.form.cover = this.qiniuData.host + info.file.response.key
        this.imgLoading = false
      }
    },
    async beforeUpload() {
      // 图片上传检验
      // await this.$examineImgWH(file)
      return true
    },
    getSpecification() {
      // 获取规格列表
      this.$axios.getSpecification().then((res) => {
        this.specifis = res.data.data
      })
    },
    handleOpen(dataList) {
      // 新增设备
      this.drawerTitle = '新增设备'
      this.visible = true
      // console.log("dataList",dataList)
      this.dataList = dataList
      this.getSpecification()
    },
    handleEdit(e) {
      // 编辑
      this.drawerTitle = '编辑'
      this.form.name = e.name
      this.form.price = e.price
      this.form.subtitle = e.subtitle
      this.form.sort = e.sort
      this.form.cover = e.cover
      this.form.specifiId = e.specifiId
      deviceId = e.id
      this.visible = true
      this.getSpecification()
    },
    handleDelete(e) {
      // 删除
      this.$confirm({
        title: '确定删除?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          const data = {
            id: e
          }
          this.$axios.delPublicServiceItemsEquipment(data).then(() => {
            this.$message.success('操作成功')
            // this.getPublicServiceItems()
            this.getPublicServiceItemsEquipment()
          })
        }
      })
    },
    handleDrawerClose() {
      // 关闭表单抽屉
      this.visible = false
      deviceId = ''
      this.$refs.ruleForm.resetFields()
    },
    handleDrawerSubmit() {
      // 提交表单数据
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (deviceId) {
            console.log('val', valid)
            this.form.id = deviceId
            this.$axios.editPublicServiceItemsEquipment(this.form).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getPublicServiceItemsEquipment()
            })
          } else {
            console.log(
              'valid',
              innerDataIndex,
              this.dataSource,
              this.dataList.id
            )
            // this.form.servingId = this.dataSource[innerDataIndex].id
            this.form.servingId = this.dataList.id
            this.$axios.addPublicServiceItemsEquipment(this.form).then(() => {
              this.$message.success('操作成功')
              this.handleDrawerClose()
              this.getPublicServiceItemsEquipment()
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.cover {
  width: 50px;
  height: 50px;
}

.inner-table {
  background-color: #fafafa;
}
</style>
