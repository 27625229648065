<template>
  <!-- 渠道商 -->
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />
    <div class="main-content">
      <div class="body" v-if="info">
        <div class="body-left">
          <div class="user">
            <div class="avatar">
              <img class="img" :src="info.avatar" />
            </div>
            <div class="name">{{ info.realName }}</div>
            <a-tag color="green" v-if="info.status === 1">
              监工管理状态：{{ statuText[info.status] }}
            </a-tag>
            <a-tag v-else>监工管理状态：{{ statuText[info.status] }}</a-tag>
          </div>
        </div>
        <div class="body-right">
          <div class="text-content" style="position: relative">
            <div class="content-item">
              <div class="content-item-header">
                <div class="title">监工管理信息</div>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">真实姓名：</span>
                  <span>{{ info.realName }}</span>
                </div>
                <div class="li">
                  <span class="label">使用者姓名：</span>
                  <span>{{ info.username }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">微信昵称：</span>
                  <span>{{ info.nickname }}</span>
                </div>
                <div class="li">
                 <span class="label">系统账号：</span>
                  <span>{{ info.systemNum }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label"> 收入：</span>
                  <span>{{ info.price }}</span>
                </div>
                <div class="li">
                  <span class="label">创建时间：</span>
                  <span>{{ info.createAt }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import NP from 'number-precision'
export default {
  data() {
    return {
      info: null,
      statuText: {
        1: '启用',
        0: '禁用'
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    const id = this.$route.query.id
    this.getChannelBusinessInfo(id)
  },
  methods: {
    getChannelBusinessInfo(id) {
      const data = {
        id
      }
      this.$axios.getFindFowller(data).then((res) => {
        const d = res.data.data
        // d.rate = NP.times(d.rate, 100)
        this.info = d
        console.log('info', this.info)
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
/* .qrcode {
  display: block;
  margin: 0 auto;
} */
.body {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.body-left,
.text-content,
.id-card-content {
  background-color: #fff;
  border-radius: 2px;
}

.body-left {
  width: 238px;
  height: 100%;
  padding: 32px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 3px;
  margin: 0 auto;
}

.name {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  margin: 8px 0;
}

.body-right {
  flex: 1;
  margin-left: 24px;
}

.text-content,
.id-card-content {
  padding: 20px 32px 24px 32px;
}

.id-card-content {
  margin-top: 16px;
}

.content-item-flex {
  display: flex;
  align-items: center;
}

.content-item-header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-item-header .title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.content-sub-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.ul-1 {
  margin-top: 16px;
}

.ul:not(:last-child) {
  margin-bottom: 16px;
}

.ul .li {
  width: 240px;
  display: inline-block;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul .li:not(:last-child) {
  margin-right: 46px;
}

.ul .label {
  font-weight: bold;
}
</style>
